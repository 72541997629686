import React from "react";
import { contactDetails } from "../Details";

function Contact() {
  const { email, phone, phone2 } = contactDetails;
  return (
    <main className="container mx-auto max-width section">
      <h1 className="text-center text-2xl md:text-3xl lg:text-6xl text-dark-heading dark:text-light-heading font-semibold md:font-bold">
        For any questions please drop a mail
      </h1>
      <div class="md:flex justify-center items-center">
        <h3 class="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-2 md:py-6 m-10">
          <a href={`mailto:${email}`}>Mail</a>
        </h3>

        <h3 class="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-2 md:py-6 m-10">
          <a href={`https://wa.me/${phone}`}>Whatsapp</a>
        </h3>
      </div>
    </main>
  );
}

export default Contact;
