import React from "react";
import Work from "../Components/Work";
import Resume from "../../src/assets/selim_en_resume.pdf";
import { personalDetails, workDetails, eduDetails } from "../Details";

function About() {
  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
     <div className="md:container md:flex justify-center">
     <section>
        <h1 className="   mr-10 text-2xl text-center text-dark-heading dark:text-light-heading md:text-6xl xl:text-6xl xl:leading-tight font-bold">
        About Me </h1></section>
        
         <section className=" mt-7 "><a
              href={Resume} download={"Resume"}
              target="_blank"
              rel="noreferrer noopener"
              className="btn ml-20 bg-greenbg md:text-2xl xl:text-2xl text-green-text rounded-3xl px-3 py-2 font-bold min-w-fit"
            >
              Download Resume
            </a>
            </section>
            </div>
        <section>
        <p className="text-content text-2xl py-8 lg:max-w-6xl">{personalDetails.about}</p>
      </section>
      {/* <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Work Experience
        </h1>
        {React.Children.toArray(
          workDetails.map(({ Position, Company, Location, Type, Duration }) => (
            <Work
              position={Position}
              company={Company}
              location={Location}
              type={Type}
              duration={Duration}
            />
          ))
        )}
      </section> */}
      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Education
        </h1>
        {React.Children.toArray(
          eduDetails.map(({ Position, Company, Location, Typelink, Duration }) => (
            <Work
              position={Position}
              company={Company}
              location={Location}
              Typelink={Typelink}
              duration={Duration}
            />
          ))
        )}
      </section>
    </main>
  );
}

export default About;
