// Enter all your detials in this file
// Logo images
// import logogradient from "./assets/logo.svg";
// import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import wordpress from "./assets/techstack/wordpress.png";
// import sass from "./assets/techstack/sass.png";
// import redux from "./assets/techstack/redux.png";
// import bootstrap from "./assets/techstack/bootstrap.png";
// import tailwind from "./assets/techstack/tailwind.png";
// import figma from "./assets/techstack/figma.png";

// Porject Images
import projectImage1 from "./assets/projects/hthukuk.jpg";
import projectImage2 from "./assets/projects/celikotocekici.jpg";
import projectImage3 from "./assets/projects/noahstrailers.jpg";
import projectImage4 from "./assets/projects/bookstore.jpg";
// import projectImage5 from "./assets/projects/project5.jpg";
// import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const title = {
  title: 'devxpe',
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Osman Selim SOYDAN",
  tagline: (
    <> <br />
      Sometimes <br /><code>{'< Web />";'}</code>

      <br />
      Mostly <br /><code>{'import React from "react";'}</code>
    </>
  ),
  img: profile,
  about: `As an experienced professional in the software industry, I initiated
  my career by working on projects with various programming
  languages. Recently, my primary focus has been on React Native,
  and I have dedicated myself to continuous improvement in this
  field. By sharing various software projects on my GitHub page, I
  have contributed to the open-source community. I have
  demonstrated strong problem-solving skills and an innovative
  thinking approach, showcasing successes in projects. My current
  goal is to deepen my expertise in React Native and develop creative
  and effective mobile applications. With a diligent, open-minded,
  and team-oriented approach, I maintain my excitement for
  exploring new opportunities in the software world.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/developerxpe/",
  github: "https://github.com/developerxpe",
  twitter: "https://twitter.com/developerxpe",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Frontend Web Developer",
    Company: `Company Name here`,
    Location: "Bengaluru",
    Type: "Full Time",
    Duration: "Sep 2021 - Dec 2021",
  },
  {
    Position: "Internship",
    Company: `Company Name here`,
    Location: "Bengaluru",
    Type: "Internship",
    Duration: "Sep 2021 - Dec 2021",
  },
  {
    Position: "Internship",
    Company: `Company Name here`,
    Location: "Bengaluru",
    Type: "Internship",
    Duration: "Sep 2021 - Dec 2021",
  },
];
// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Dynamic Web Applications Training with Java and Spring Boot 2.0",
    Company: "Udemy",
    Location: "Online",
    Typelink: "{Typelink}",
    // Duration: "Jan 2022 - Present",
  },
 /*  {
    Position: "Bachelor in Electronics & Communication",
    Company: `Your College Name here`,
    Location: "Bengaluru",
    Type: "Full Time",
    Duration: "Aug 2020 - Present",
  }, */
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  // redux: redux,
  // sass: sass,
  // tailwind: tailwind,
  // bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  wordpress: wordpress,
  // figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "HT Hukuk",
    image: projectImage1,
    description: `A company consisting of a number of lawyers practicing law in Turkey`,
    techstack: "Wordpress, Elementor Pro",
    previewLink: "https://hthukuk.com/",
    githubLink: "/#",
  },
  {
    title: "Çelik Oto Çekici",
    image: projectImage2,
    description: `Tow truck service serving in many points of Istanbul`,
    techstack: "Wordpress, Elementor Pro",
    previewLink: "https://celikotocekici.com/",
    githubLink: "/#",
  },
  {
    title: "Noahs Trailers",
    image: projectImage3,
    description: `A caravan marketing site that produces in Turkey and serves in the USA.`,
    techstack: "Wordpress, Elementor Pro",
    previewLink: "https://noahstrailers.com",
    githubLink: "/#",
  },
  {
    title: "Bookstore",
    image: projectImage4,
    description: `To improve myself, I rebuilt the previously made project from scratch.`,
    techstack: "Springboot, Java, Bootstrap",
    previewLink: "/#",
    githubLink: "https://github.com/developerxpe/bookstore",
  },
  /* {
    title: "Project title 5",
    image: projectImage5,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  },
  {
    title: "Project title 6",
    image: projectImage6,
    description: `This is sample project description random things are here in description This is sample
project lorem ipsum generator for dummy content`,
    techstack: "HTML/CSS, JavaScript",
    previewLink: "https://google.com",
    githubLink: "https://github.com",
  }, */
];

// Enter your Contact Details here
export const contactDetails = {
  email: "hello@soydan.me",
  phone: "+6285137564668",
};
